import React, { useEffect, useState } from 'react';

import ReactApexChart from 'react-apexcharts';
import './ApexChart.css';

function ApexChart({ seriesData, width, height, colors }) {
  const [dataSeries, setDataSeries] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    // When seriesData changes, update dataSeries
    // Ensure that the timestamp values are converted to numbers
    const formattedData = seriesData.map((dataPoint) => ({
      x: Number(dataPoint.x), // Convert to number
      y: dataPoint.y,
    }));
    setDataSeries(formattedData);
  }, [seriesData]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (hasMounted) {
      setChartData([
        {
          series: [
            {
              name: 'Price',
              data: dataSeries, // Use dataSeries here
            },
          ],
          options: {
            chart: {
              id: 'chart4',
              group: 'socialSZSE',
              type: 'area',
              height: height,
              width: width,
            },
            colors: [colors],
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              type: 'datetime', // Use datetime type for x-axis
              labels: {
                style: {
                  colors: 'black',
                },
              },
              tooltip: {
                enabled: false, // Disable tooltip for x-axis
              },
            },
            yaxis: {
              opposite: false,
              labels: {
                style: {
                  colors: 'black',
                },
                formatter: function (val) {
                  // Round the value to two decimal places
                  const roundedValue = Math.round(val * 100) / 100;
                  // Convert the rounded value to a string and remove trailing ".00"
                  const formattedValue = roundedValue
                    .toFixed(2)
                    .replace(/\.00$/, '');
                  // Use commas for thousands separator
                  return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
              },
            },
            tooltip: {
              theme: 'dark',
              style: {
                fontSize: '14px',
              },
              x: {
                format: 'HH:mm', // Format tooltip x value as HH:mm
              },
            },
          },
        },
      ]);
    }
  }, [dataSeries, hasMounted, width, height, colors]);

  return (
    <div id='wrapperSZSE' className='chart-container'>
      {/* Add a CSS class */}
      {chartData.map((chart, index) => (
        <div key={index} className='chart-section' id='chartSZSE'>
          <ReactApexChart
            id='chartSZSE'
            options={chart.options}
            // series={chart.series}
            series={[
              // Create a series with a name and the actual data points
              {
                name: 'Price',
                data: dataSeries,
              },
            ]}
            type={chart.options.chart.type}
            height={chart.options.chart.height}
            width={chart.options.chart.width}
          />
        </div>
      ))}
    </div>
  );
}

export default ApexChart;
