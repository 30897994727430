import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableTodayReward from './components/TableTodayReward';
import ListTodayStockData from './components/ListTodayStockData';

import { myConfig } from '../../Constants/Constants';
const API_URL = myConfig.apiUrl;

// const API_URL = 'http://localhost:3300/api';
// const API_URL = 'https://bnagvip.slotxojackpot.com/api';

const HangSeng = () => {
  const [data, setData] = useState(null);
  const [dataStock, setDataStock] = useState([]);
  const [rewardResult, setRewardResult] = useState(null);
  const [rewardHistoryResult, setRewardHistoryResult] = useState(null);

  const fetchLotteryRewardResult = async () => {
    try {
      const requestBody = {
        lotteryId: '6505ecc4c4d607409d6f3b1f',
      };

      const response = await axios.post(
        `${API_URL}/lottery-reward-result-hs`,
        requestBody
      );

      // Check if the request was successful
      if (response.status === 200) {
        // console.log('response.data - [HangSeng] : ', response.data);
        // console.log('response.data : ', response.data.lotteryRewardResult);
        // console.log('response.data.results : ', response.data.results.dateString);
        setData(response.data.results);
        setDataStock(response.data.results.stocks);
        setRewardResult(response.data.lotteryRewardResult);
        setRewardHistoryResult(response.data.historyReward);

        // return response.data.results;
      } else {
        console.error(
          'API request failed:',
          response.status,
          response.statusText
        );
        return null;
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data from the API:',
        error
      );
      return null;
    }
  };

  const fetchAndSetData = async () => {
    try {
      await fetchLotteryRewardResult();
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchAndSetData();

    const interval = setInterval(async () => {
      await fetchAndSetData();
    }, 3 * 60 * 1000); // Fetch data every 3 minutes

    return () => clearInterval(interval);
  }, []);

  const mapStockName = (originalName) => {
    const nameMappings = {
      HangSengIndex: 'Hang Seng Index',
      HangSengCN: 'Hang Seng China Enterprises Index',
      HangSengTI: 'Hang Seng TECH Index',
      HangSengCI: 'Hang Seng Composite Index',
    };

    return nameMappings[originalName] || originalName;
  };

  return (
    <>
      <div className='header'>
        <img src='assets/img/hk-header.png' />
      </div>
      <div className='top-nav'>
        <ul>
          <li>
            <a href='#'>About Us</a>
          </li>
          <li>
            <a href='#'>Contact Us</a>
          </li>
          <li>
            <a href='#'>繁</a>
          </li>
          <li>
            <a href='#'>简</a>
          </li>
        </ul>
      </div>
      <div className='nav'>
        <img src='assets/img/logo_hangseng.png' />
        <div className='menu'>
          <ul>
            <li>
              <a href='#'>Indexes</a>
            </li>
            <li>
              <a href='#'>Our Services</a>
            </li>
            <li>
              <a href='#'>ESG Service</a>
            </li>
            <li>
              <a href='#'>Resources</a>
            </li>
            <li>
              <a href='#'>Newsroom</a>
            </li>
          </ul>
        </div>
      </div>
      <div className='content'>
        <div className='arrow-left'>
          <span>
            <i className='fas fa-chevron-left' />
          </span>
        </div>
        <div className='index-box'>
          <div className='index-tab'>
            <ul>
              <li className='first'>
                HONG KONG-LISTED
                <div className='arrow' />
              </li>
              <li className='second'>MAINLAND-LISTED</li>
              <li className='third'>CROSS-MARKET</li>
            </ul>
          </div>
          <div className='index-cont m-0'>
            <ListTodayStockData
              dataStock={dataStock}
              data={data}
              mapStockName={mapStockName}
            />
          </div>
          <div className='today-result'>
            <table className='table table-bordered'>
              <TableTodayReward rewardResult={rewardResult} />
            </table>
          </div>
        </div>
        <div className='arrow-right'>
          <span>
            <i className='fas fa-chevron-right' />
          </span>
        </div>
      </div>
      <div className='mob-index'>
        <div className='index-box'>
          <div className='index-tab'>
            <ul>
              <li className='first'>HONG KONG-LISTED</li>
            </ul>
          </div>
          <div className='index-cont m-0'>
            <ListTodayStockData
              dataStock={dataStock}
              data={data}
              mapStockName={mapStockName}
            />
          </div>
          <div className='today-result'>
            <table className='table table-bordered'>
              <TableTodayReward rewardResult={rewardResult} />
            </table>
          </div>
        </div>
        <div className='second'>
          <span>MAINLAND-LISTED</span>
          <i className='fas fa-chevron-down' />
        </div>
        <div className='third'>
          <span>CROSS-MARKET</span>
          <i className='fas fa-chevron-down' />
        </div>
      </div>
      <div className='text-center mt-2'>
        <a
          href='https://hangseng-vi.com/'
          target='_blank'
          style={{ fontSize: '110%' }}
          className='txt-h'
        >
          www.hangseng-vi.com
        </a>
      </div>
      <div className='hk-final-footer' />
    </>
  );
};

export default HangSeng;
