import React from 'react';

import ErrorBoundary from './ErrorBoundary';

import Nikkei from './ApexChart/Nikkei/Nikkei';
import China from './ApexChart/China/China';
import HangSeng from './ApexChart/HangSeng/HangSeng';
import Taiwan from './ApexChart/Taiwan/Taiwan';
import Korea from './ApexChart/Korea/Korea';
import Singapore from './ApexChart/Singapore/Singapore';
import England from './ApexChart/England/England';
import Germany from './ApexChart/Germany/Germany';
import Russia from './ApexChart/Russia/Russia';
import DowJones from './ApexChart/DowJones/DowJones';

function App() {
  return (
    <>
      <ErrorBoundary>
        <div className='container-fluid px-0'>
          <div className='row mx-0'>
            <div className='col-12 col-xl-8 offset-xl-2 px-0'>
              {/* Nikkei */}
              <div id='jpindex'>
                <Nikkei width={'100%'} height={200} colors={'#008FFB'} />
              </div>

              {/* China SZSE */}
              <div className='pt-5' id='cnindex'>
                <China width={'100%'} height={280} colors={'#d7e8ff'} />
              </div>

              {/* HangSeng */}
              <div className='pt-5' id='hkindex'>
                <HangSeng />
              </div>

              {/* Taiwan */}
              <div className='pt-5' id='twindex'>
                <Taiwan
                  width={'100%'}
                  height={200}
                  colors={'rgb(100, 78, 250)'}
                />
              </div>

              {/* Korea */}
              <div div className='pt-5' id='krindex'>
                <Korea />
              </div>

              {/* Singapore */}
              <div className='pt-5' id='sgindex'>
                <Singapore width={'100%'} height={280} colors={'#d7e8ff'} />
              </div>

              {/* England */}
              <div className='pt-5' id='gb-index'>
                <England width={'100%'} height={250} colors={'#fe9e19'} />
              </div>

              {/* Germany */}
              <div className='pt-5' id='de-index'>
                <Germany width={'100%'} height={250} colors={'#b1b9c4'} />
              </div>

              {/* Russia */}
              <div className='pt-5' id='ru-index'>
                <Russia
                  width={'100%'}
                  height={300}
                  colors={'rgba(33,150,243,0.65)'}
                />
              </div>

              {/* DowJones */}
              <div className='pt-5' id='dj-index'>
                <DowJones
                  width={'100%'}
                  height={300}
                  colors={'rgba(92,10,26,0.65)'}
                />
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </>
  );
}

export default App;
