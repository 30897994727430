import React from 'react';

const TableTodayReward = ({ rewardResult }) => {
  // console.log("TableTodayReward : ", rewardResult);
  return (
    <tbody>
      <tr>
        <td>ปิดเช้า</td>
        <td>บน</td>
        <td
          style={{
            fontSize: 16,
            fontWeight: 700,
            color: '#ee1d24',
          }}
        >
          {rewardResult?.Morning?.digits3 || ''}
        </td>
        <td>ล่าง</td>
        <td
          style={{
            fontSize: 16,
            fontWeight: 700,
            color: '#ee1d24',
          }}
        >
          {rewardResult?.Morning?.digits2 || ''}
        </td>
      </tr>
      <tr>
        <td>ปิดเย็น</td>
        <td>บน</td>
        <td
          style={{
            fontSize: 16,
            fontWeight: 700,
            color: '#ee1d24',
          }}
        >
          {rewardResult?.Evening?.digits3 || ''}
        </td>
        <td>ล่าง</td>
        <td
          style={{
            fontSize: 16,
            fontWeight: 700,
            color: '#ee1d24',
          }}
        >
          {rewardResult?.Evening?.digits2 || ''}
        </td>
      </tr>
    </tbody>
  );
};

export default TableTodayReward;
