import React, { useEffect, useState } from 'react';
import axios from 'axios';

import ApexChart from './ApexChart';

import { myConfig } from '../../Constants/Constants';
const API_URL = myConfig.apiUrl;

// const API_URL = 'http://localhost:3300/api';
// const API_URL = 'https://bnagvip.slotxojackpot.com/api';

const Singapore = ({ width, height, colors }) => {
  const [currentDate, setCurrentDate] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [resultR1, setResultR1] = useState({});
  const [resultR2, setResultR2] = useState({});
  const [flagClose, setFlagClose] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [timeOfResultShow, setTimeOfResultShow] = useState(null);
  const [selectedResult, setSelectedResult] = useState('');
  const [currentTimeHHMM, setCurrentTimeHHMM] = useState('');
  const [rewardResult, setRewardResult] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const [openPrice, setOpenPrice] = useState('');
  const [prevPrice, setPrevPrice] = useState('');
  const [highestPrice, setHighestPrice] = useState('');
  const [lowestPrice, setLowestPrice] = useState('');
  const [dataResult, setDataResult] = useState([]);

  const getCurrentTimeHHMM = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const compareTimes = (time1, time2) => {
    if (!time1 || !time2) {
      // Handle undefined time strings gracefully
      return 0; // or return an appropriate value
    }

    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    if (hours1 < hours2) return -1;
    if (hours1 > hours2) return 1;
    if (minutes1 < minutes2) return -1;
    if (minutes1 > minutes2) return 1;
    return 0;
  };

  const formatNumberWithPlus = (numberString) => {
    const number = parseFloat(numberString);

    if (!isNaN(number) && number >= 0) {
      return `+${number.toFixed(2)}`;
    } else {
      return numberString;
    }
  };

  const convertFormatDateMonthDayYear = (getDateTime) => {
    // console.log('getDateTime : ', getDateTime);
    const inputDate = new Date(getDateTime);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const formattedDateTime = `${
      months[inputDate.getMonth()]
    } ${inputDate.getDate()}, ${inputDate.getFullYear()} ${inputDate.toLocaleTimeString(
      [],
      { hour: '2-digit', minute: '2-digit' }
    )}`;

    const formattedDate = `${
      months[inputDate.getMonth()]
    } ${inputDate.getDate()}, ${inputDate.getFullYear()}`;

    return formattedDate;
  };

  const fetchLotteryRewardResult = async (getDate) => {
    try {
      if (dataFetched) {
        return;
      }

      const requestBody = {
        lotteryId: '6505f98435d1e4e1ae1ae3c1',
        startTime: '09:00',
        endTime: '23:50',
      };

      const response = await axios.post(
        `${API_URL}/lottery-reward-result-sg`,
        requestBody
      );

      // Check if the request was successful
      if (response.status === 200) {
        // console.log('response.data : ', response.data);

        let data = response.data.results.results;
        // if (data.r1 && data.r1.price !== null && data.r1.price !== undefined && data.r1.price !== "") {
        if (data.r1?.price) {
          setResultR1(response.data.results.results.r1);
          setFlagClose(false);
        } else {
          setResultR1({});
          setFlagClose(false);
        }

        if (data.r2?.price) {
          setResultR2(response.data.results.results.r2);
          setFlagClose(true);
        } else {
          setResultR2({});
          setFlagClose(false);
        }

        // console.log(response.data.results.stocks);

        setOpenPrice(response.data.openPrice);
        setPrevPrice(response.data.prevPrice);
        setStockData(response.data.results.stocks);
        setTimeOfResultShow(response.data.timeOfResultShow);
        let currentTimeHHMM = getCurrentTimeHHMM();
        setCurrentTimeHHMM(currentTimeHHMM);
        const selectedResult =
          currentTimeHHMM >= response.data.timeOfResultShow.Morning
            ? 'resultR1'
            : 'resultR2';

        setSelectedResult(selectedResult);

        setCurrentDate(
          convertFormatDateMonthDayYear(response.data.results.dateString)
        );

        setCurrentDate(
          convertFormatDateMonthDayYear(response.data.results.dateString)
        );

        // console.log(response.data.lotteryRewardResult);
        setDataResult(response.data.results);
        setLowestPrice(response.data.lowestPrice);
        setHighestPrice(response.data.highestPrice);
        setRewardResult(response.data.lotteryRewardResult);
        setHistoryData(response.data.historyReward);
        setSeriesData(response.data.seriesData);
      } else {
        console.error(
          'API request failed:',
          response.status,
          response.statusText
        );
        return null;
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data from the API:',
        error
      );
      return null;
    }
  };

  useEffect(() => {
    const now = new Date();
    const months = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    const year = now.getFullYear();
    const month = months[now.getMonth()];
    const day = String(now.getDate()).padStart(2, '0');

    const sendBodyDate = `${year}-${month}-${day}`;
    fetchLotteryRewardResult(sendBodyDate);

    const interval = setInterval(async () => {
      await fetchLotteryRewardResult(sendBodyDate);
    }, 3 * 60 * 1000); // Fetch data every 3 minutes

    return () => clearInterval(interval);
  }, [dataFetched]);

  const convertFormatDate = (dateString) => {
    const dateObj = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Get the month, day, and year components from the date object
    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  const convertFormatDateYYYYMMDD = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and format.
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  };

  const convertFormatDateDDMMYYYY = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and format.
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const convertFormatDateDDMMYYYYWithTime = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    const formattedHours = hours % 12 || 12;

    const formattedDate = `${day} ${month} ${year} ${formattedHours}:${minutes} ${ampm}`;

    return formattedDate;
  };

  const formatNumberWithCommas_v1 = (number) => {
    return number.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatNumberWithCommas = (number) => {
    // Check if the input is a valid number and not NaN
    if (typeof number === 'number' && !isNaN(number)) {
      return number.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      // If the input is not a valid number or NaN, return "0.00"
      return '0.00';
    }
  };

  const getRandomValueAroundPercentage = (value, percentage) => {
    // console.log('value, percentage : ', value, percentage);
    const minValue = value * percentage;
    const maxValue = -minValue;

    // Generate a random value within the defined range
    const randomValue = Math.random() * (maxValue - minValue) + minValue;

    // Check if the result is NaN or not a finite number
    if (isNaN(randomValue) || !isFinite(randomValue)) {
      //   console.warn(
      //     `Random value is not a valid number, returning the original value ${value}`
      //   );
      return value;
    }

    // Add the random value to the original value and round to two decimal places
    const result = (value + randomValue).toFixed(2);

    // console.log('result : ', result);
    return result;
  };

  const formatDateLastUpdated = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Determine AM/PM based on UTC hours
    const ampm = date.getUTCHours() >= 12 ? 'pm' : 'am';

    const formattedDate = `Last Updated: ${month} ${day}, ${year} ${hours}:${minutes} ${ampm} CEDT`;

    return formattedDate;
  };

  return (
    <>
      <div className='header'>
        <img src='assets/img/sg-header.png' />
      </div>
      <div className='sg-content'>
        <div className='sg-content-header'>
          <h2>Singapore Exchange VI (Value Investor)</h2>
        </div>
        <div className='price-chart'>
          <div className='title'>
            <h2>Prices &amp; Chart</h2>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4'>
                {stockData[0]?.priceData[0]?.status === 'up' ? (
                  <div className='sgx-price sgx-price--up'>
                    <div className='sgx-price-value'>
                      <i className='fa-solid fa-caret-up' />
                      {parseFloat(stockData[0]?.priceData[0]?.price)}
                    </div>
                    <div className='sgx-price-change'>
                      ({parseFloat(stockData[0]?.priceData[0]?.diff)}/
                      {parseFloat(stockData[0]?.priceData[0]?.per)}%)
                    </div>
                  </div>
                ) : (
                  <div className='sgx-price sgx-price--down'>
                    <div className='sgx-price-value'>
                      <i className='fa-solid fa-caret-down' />
                      {parseFloat(stockData[0]?.priceData[0]?.price)}
                    </div>
                    <div className='sgx-price-change'>
                      ({parseFloat(stockData[0]?.priceData[0]?.diff)}/
                      {parseFloat(stockData[0]?.priceData[0]?.per)}%)
                    </div>
                  </div>
                )}

                <table className='table table-striped text-small sgx-product-price-chart-table'>
                  <tbody>
                    <tr>
                      <th>Date</th>
                      <td>{convertFormatDate(dataResult?.date)}</td>
                    </tr>
                    <tr>
                      <th>Base Value</th>
                      <td>420.000</td>
                    </tr>
                    <tr>
                      <th>Open</th>
                      <td>
                        {formatNumberWithCommas(parseFloat(openPrice?.price))}
                      </td>
                    </tr>
                    <tr>
                      <th>Prev Close</th>
                      <td>
                        {formatNumberWithCommas(parseFloat(prevPrice?.price))}
                      </td>
                    </tr>
                    <tr>
                      <th>52 Week High</th>
                      <td>
                        {formatNumberWithCommas(
                          parseFloat(
                            getRandomValueAroundPercentage(
                              parseFloat(highestPrice),
                              0.31
                            )
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>52 Week Low</th>
                      <td>
                        {lowestPrice &&
                          formatNumberWithCommas(
                            parseFloat(
                              getRandomValueAroundPercentage(
                                parseFloat(lowestPrice),
                                0.23
                              )
                            )
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th>Day High</th>
                      <td>
                        {formatNumberWithCommas(parseFloat(highestPrice))}
                      </td>
                    </tr>
                    <tr>
                      <th>Day Low</th>
                      <td>{formatNumberWithCommas(parseFloat(lowestPrice))}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='col-md-8'>
                <div className='sgx-state-tabs'>
                  <div className='sgx-state-tabs-scroll-wrapper'>
                    <div className='sgx-state-tabs-container'>
                      <span
                        title='Day'
                        className='sgx-state-tab sgx-state-tab--active sgx-state-tab--animate-active'
                      >
                        <span>Day</span>
                      </span>
                      <span title='Week' className='sgx-state-tab'>
                        <span>Week</span>
                      </span>
                      <span title='Month' className='sgx-state-tab'>
                        <span>Month</span>
                      </span>
                      <span
                        title='1 Year'
                        className='sgx-state-tab sgx-state-tab--animate-inactive'
                      >
                        <span>1 Year</span>
                      </span>
                      <span title='5 Years' className='sgx-state-tab'>
                        <span>5 Years</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='mt-3' style={{ position: 'relative' }}>
                  <div style={{ minHeight: 295 }}>
                    <div
                      style={{
                        width: '100%',
                        height: 280,
                        overflow: 'hidden',
                      }}
                    >
                      <ApexChart
                        seriesData={seriesData}
                        width={width}
                        height={height}
                        colors={colors}
                      />
                    </div>
                  </div>
                  <div className='today-result mt-3'>
                    <table className='table table-bordered'>
                      <tbody>
                        <tr>
                          <td>{currentDate}</td>
                          <td>บน</td>
                          <td
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: '#24d304',
                            }}
                          >
                            {timeOfResultShow && timeOfResultShow['Morning']
                              ? rewardResult?.Morning?.digits3
                              : rewardResult?.Evening?.digits3}
                          </td>
                          <td>ล่าง</td>
                          <td
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: '#24d304',
                            }}
                          >
                            {timeOfResultShow && timeOfResultShow['Morning']
                              ? rewardResult?.Morning?.digits2
                              : rewardResult?.Evening?.digits2}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className='text-end'
                    style={{
                      fontSize: 12,
                      color: 'rgb(95, 96, 98)',
                      lineHeight: '1.3',
                    }}
                  >
                    As at {convertFormatDateDDMMYYYYWithTime(dataResult.date)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='text-center p-2'
        style={{
          backgroundColor: 'rgb(231, 242, 243)',
          margin: '10px 15px 0px',
        }}
      >
        <a
          href='https://sgx-vi.com/'
          target='_blank'
          style={{ fontSize: '110%' }}
          className='txt-h'
        >
          www.sgx-vi.com
        </a>
      </div>
      <div className='sg-final-footer' />
    </>
  );
};

export default Singapore;
