import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ApexChart from './ApexChart';

import { myConfig } from '../../Constants/Constants';
const API_URL = myConfig.apiUrl;

// const API_URL = 'https://bnagvip.slotxojackpot.com/api';

const Nikkei = ({ width, height, colors }) => {
  const [currentDate, setCurrentDate] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [resultR1, setResultR1] = useState({});
  const [resultR2, setResultR2] = useState({});
  const [flagClose, setFlagClose] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [timeOfResultShow, setTimeOfResultShow] = useState(null);
  const [selectedResult, setSelectedResult] = useState('');
  const [currentTimeHHMM, setCurrentTimeHHMM] = useState('');
  const [rewardResult, setRewardResult] = useState('');

  const getCurrentTimeHHMM = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const compareTimes = (time1, time2) => {
    if (!time1 || !time2) {
      // Handle undefined time strings gracefully
      return 0; // or return an appropriate value
    }

    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    if (hours1 < hours2) return -1;
    if (hours1 > hours2) return 1;
    if (minutes1 < minutes2) return -1;
    if (minutes1 > minutes2) return 1;
    return 0;
  };

  const formatNumberWithPlus = (numberString) => {
    const number = parseFloat(numberString);

    if (!isNaN(number) && number >= 0) {
      return `+${number.toFixed(2)}`;
    } else {
      return numberString;
    }
  };

  const fetchLotteryRewardResult = async (getDate) => {
    try {
      const requestBody = {
        lotteryId: '6505f82935d1e4e1ae1ae3a1',
        startTime: '09:00',
        endTime: '17:00',
      };

      const response = await axios.post(
        `${API_URL}/lottery-reward-result-nk`,
        requestBody
      );

      // Check if the request was successful
      if (response.status === 200) {
        // console.log('response.data : ', response.data);

        let data = response.data.results.results;
        // if (data.r1 && data.r1.price !== null && data.r1.price !== undefined && data.r1.price !== "") {
        if (data.r1?.price) {
          setResultR1(response.data.results.results.r1);
          setFlagClose(false);
        } else {
          setResultR1({});
          setFlagClose(false);
        }

        if (data.r2?.price) {
          setResultR2(response.data.results.results.r2);
          setFlagClose(true);
        } else {
          setResultR2({});
          setFlagClose(false);
        }

        // console.log(response.data.results.stocks[0].priceData)

        setStockData(response.data.results.stocks[0].priceData);
        setTimeOfResultShow(response.data.timeOfResultShow);
        console.log(
          'response.data.timeOfResultShow : ',
          response.data.timeOfResultShow
        );
        let currentTimeHHMM = getCurrentTimeHHMM();
        setCurrentTimeHHMM(currentTimeHHMM);
        const selectedResult =
          currentTimeHHMM >= response.data.timeOfResultShow.Morning
            ? 'resultR1'
            : 'resultR2';

        setSelectedResult(selectedResult);

        setCurrentDate(convertFormatDate(response.data.results.dateString));

        console.log(response.data.lotteryRewardResult);
        setRewardResult(response.data.lotteryRewardResult);
        setHistoryData(response.data.historyReward);
        setSeriesData(response.data.seriesData);
      } else {
        console.error(
          'API request failed:',
          response.status,
          response.statusText
        );
        return null;
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data from the API:',
        error
      );
      return null;
    }
  };

  useEffect(() => {
    const now = new Date();
    const months = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    const year = now.getFullYear();
    const month = months[now.getMonth()];
    const day = String(now.getDate()).padStart(2, '0');

    const sendBodyDate = `${year}-${month}-${day}`;
    fetchLotteryRewardResult(sendBodyDate);

    const interval = setInterval(async () => {
      await fetchLotteryRewardResult(sendBodyDate);
    }, 3 * 60 * 1000); // Fetch data every 3 minutes

    return () => clearInterval(interval);
  }, []);

  const convertFormatDate = (dateString) => {
    const dateObj = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Get the month, day, and year components from the date object
    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    // Create the formatted date string
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const formatNumberWithCommas_v1 = (number) => {
    return number.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatNumberWithCommas = (number) => {
    // Check if the input is a valid number and not NaN
    if (typeof number === 'number' && !isNaN(number)) {
      return number.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      // If the input is not a valid number or NaN, return "0.00"
      return '0.00';
    }
  };

  const getDigitResult = async (results) => {
    console.log('getDigitResult : ', results);

    // Extract the desired values
    // console.log('results - [getMorningResult] : ', results);
    const r1Price = parseFloat(results.price);
    const r1Diff = parseFloat(results.diff);

    // console.log('r1Price : ', r1Price);
    // console.log('r1Diff : ', r1Diff);

    let parts = r1Price.toString().split('.');
    // console.log("parts : ", parts);
    let resultBeforeDot;
    let resultAfterDot;
    let lastCharacter;

    // const resultBeforeDot = parts[0];
    // const lastCharacter = resultBeforeDot.charAt(resultBeforeDot.length - 1);
    // let resultAfterDot = parts[1];

    // if (resultAfterDot.length !== 2) {
    //   resultAfterDot += '0';
    // }

    if (parts.length === 2) {
      resultBeforeDot = parts[0];
      lastCharacter = resultBeforeDot.charAt(resultBeforeDot.length - 1);
      resultAfterDot = parts[1];

      if (resultAfterDot.length !== 2) {
        resultAfterDot += '0';
      }
    } else {
      // console.log("thisss")
      resultBeforeDot = r1Price.toString();
      lastCharacter = resultBeforeDot.charAt(resultBeforeDot.length - 1);
      resultAfterDot = '00';
    }

    let digits3String = lastCharacter + resultAfterDot;
    let partsDiff = r1Diff.toString().split('.');
    let resultDiffAfterDot = partsDiff[1];

    // console.log('digits3String : ', digits3String);
    // console.log('partsDiff : ', partsDiff);
    // console.log('resultDiffAfterDot : ', resultDiffAfterDot);
    // // console.log('resultDiffAfterDot.length : ', resultDiffAfterDot.length);

    if (typeof resultDiffAfterDot !== 'undefined') {
      if (resultDiffAfterDot.length !== 2) {
        resultDiffAfterDot += '0';
      }
    } else {
      resultDiffAfterDot = '0';
    }

    let digits2String = resultDiffAfterDot;

    // Manipulate the values
    const digits3 = digits3String;
    const digits2 = digits2String;

    return { digits3, digits2 };
  };

  return (
    <>
      <div className='header'>
        <img src='assets/img/jp-header.png' />
      </div>
      <div className='nav d-flex py-0'>
        <div className='navbar-brand d-flex flex-wrap'>
          <div className='navbar-brand-top'>Nikkei VI</div>
          <div className='navbar-brand-subtop ps-2 ps-md-0'>
            -Nikkei 225 turns 70 years old-
          </div>
        </div>
        <div className='navbar-right'>
          <ul className='mb-0 ps-0'>
            <li className='language-link active'>
              <a href='#'>English</a>
            </li>
            <li className='language-link'>
              <a href='#'>簡体中文</a>
            </li>
            <li className='language-link'>
              <a href='#'>日本語</a>
            </li>
            <li className='members-link'>
              <a href='#'>For Subscribers</a>
            </li>
          </ul>
        </div>
      </div>
      <div className='jp-content'>
        <div className='row align-items-center mx-0'>
          <div className='col-xs-12 col-sm-3'>
            <div>
              <div className='l-panel'>
                <div className='top-nk225-text-nk'>Nikkei 225</div>
                <div className='top-nk225-text'>
                  {currentDate}

                  {timeOfResultShow &&
                  timeOfResultShow.Evening &&
                  compareTimes(currentTimeHHMM, timeOfResultShow.Evening) >= 0
                    ? '*Close'
                    : ''}
                </div>
                <div className='top-nk225-text-link d-flex align-items-center'>
                  <i className='fas fa-chart-line' />
                  &nbsp;
                  <a href='#' className='right'>
                    Intraday Chart
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-sm-7'>
            <div className='c-panel' style={{ position: 'relative' }}>
              <div className='align-baseline d-flex p-lg-2 col-12 mt-3'>
                {currentTimeHHMM &&
                  currentTimeHHMM.length !== 0 &&
                  (() => {
                    if (
                      compareTimes(currentTimeHHMM, '00:00') >= 0 &&
                      compareTimes(currentTimeHHMM, timeOfResultShow?.Morning) <
                        0
                    ) {
                      return (
                        <>
                          <span style={{ fontSize: 18 }}>
                            {resultR1?.price}
                          </span>
                          <span className='ps-2 pe-2'>
                            {formatNumberWithPlus(resultR2.diff)} (
                            {formatNumberWithPlus(resultR2.percent)}%)
                          </span>
                        </>
                      );
                    } else if (
                      compareTimes(
                        currentTimeHHMM,
                        timeOfResultShow?.Morning
                      ) >= 0 &&
                      compareTimes(currentTimeHHMM, timeOfResultShow?.Evening) <
                        0
                    ) {
                      return (
                        <>
                          <span style={{ fontSize: 18 }}>
                            {resultR1?.price}
                          </span>
                          <span className='ps-2 pe-2'>
                            {resultR1?.diff} ({resultR1?.percent}%)
                          </span>
                          {/* <i
                            className='fa-solid fa-square-arrow-up-right'
                            style={{ fontSize: 18 }}
                          /> */}
                        </>
                      );
                    } else if (
                      compareTimes(
                        currentTimeHHMM,
                        timeOfResultShow?.Evening
                      ) >= 0 &&
                      compareTimes(currentTimeHHMM, '23:59') <= 0
                    ) {
                      return (
                        <>
                          <span style={{ fontSize: 18 }}>
                            {resultR2?.price}
                          </span>
                          <span className='ps-2 pe-2'>
                            {resultR2?.diff} ({resultR2?.percent}%)
                          </span>
                          {/* <i
                            className='fa-solid fa-square-arrow-up-right'
                            style={{ fontSize: 18 }}
                          /> */}
                        </>
                      );
                    }
                  })()}
              </div>

              <div className='chart' style={{ overflow: 'hidden' }}>
                <div style={{ minHeight: 195 }}>
                  <div style={{ width: '100%' }}>
                    <ApexChart
                      seriesData={seriesData}
                      width={width}
                      height={height}
                      colors={colors}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='today-result'>
              <table className='table table-bordered'>
                <tbody>
                  <tr>
                    <td>ปิดเช้า</td>
                    <td>บน</td>
                    <td
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: '#ffffff',
                        textDecoration: 'underline',
                      }}
                    >
                      {rewardResult?.Morning?.digits3}
                      {/* {getDigitResult(resultR1).digits3} */}
                    </td>
                    <td>ล่าง</td>
                    <td
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: '#ffffff',
                        textDecoration: 'underline',
                      }}
                    >
                      {rewardResult?.Morning?.digits2}
                      {/* {getDigitResult(resultR1).digits2} */}
                    </td>
                  </tr>
                  <tr>
                    <td>ปิดเย็น</td>
                    <td>บน</td>
                    <td
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: '#ffffff',
                        textDecoration: 'underline',
                      }}
                    >
                      {rewardResult?.Evening?.digits3}
                      {/* {getDigitResult(resultR2).digits3} */}
                    </td>
                    <td>ล่าง</td>
                    <td
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: '#ffffff',
                        textDecoration: 'underline',
                      }}
                    >
                      {/* {getDigitResult(resultR2).digits2} */}
                      {rewardResult?.Evening?.digits2}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-xs-12 col-sm-2'>
            <div className='r-panel d-flex'>
              <div className='top-nk225-menu'>
                <a href='#'>
                  <i className='far fa-arrow-alt-circle-right' />
                  &nbsp;More Details
                </a>
              </div>
              <div className='top-nk225-menu pt-1'>
                <a href='#'>
                  <i className='far fa-arrow-alt-circle-right' />
                  &nbsp;Daily Summary
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='jp-footer'>
          <img src='assets/img/name.svg' alt />
          Nikkei Value Investor
          <a
            href='https://nikkei-vi.com/'
            target='_blank'
            style={{ color: 'rgb(255, 255, 255)', fontSize: '110%' }}
            className='w-100 txt-h'
          >
            www.nikkei-vi.com
          </a>
        </div>
        <div className='jp-final-footer' />
      </div>
    </>
  );
};

export default Nikkei;
