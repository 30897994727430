import React, { useEffect, useState } from 'react';
import axios from 'axios';

import ApexChart from './ApexChart';

import { myConfig } from '../../Constants/Constants';
const API_URL = myConfig.apiUrl;

// const API_URL = 'http://localhost:3300/api';
// const API_URL = 'https://bnagvip.slotxojackpot.com/api';

const England = ({ width, height, colors }) => {
  const [currentDate, setCurrentDate] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [resultR1, setResultR1] = useState({});
  const [resultR2, setResultR2] = useState({});
  const [flagClose, setFlagClose] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [timeOfResultShow, setTimeOfResultShow] = useState(null);
  const [selectedResult, setSelectedResult] = useState('');
  const [currentTimeHHMM, setCurrentTimeHHMM] = useState('');
  const [rewardResult, setRewardResult] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const [openPrice, setOpenPrice] = useState('');
  const [prevPrice, setPrevPrice] = useState('');
  const [highestPrice, setHighestPrice] = useState('');
  const [lowestPrice, setLowestPrice] = useState('');
  const [dataResult, setDataResult] = useState([]);

  const getCurrentTimeHHMM = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const compareTimes = (time1, time2) => {
    if (!time1 || !time2) {
      // Handle undefined time strings gracefully
      return 0; // or return an appropriate value
    }

    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    if (hours1 < hours2) return -1;
    if (hours1 > hours2) return 1;
    if (minutes1 < minutes2) return -1;
    if (minutes1 > minutes2) return 1;
    return 0;
  };

  const formatNumberWithPlus = (numberString) => {
    const number = parseFloat(numberString);

    if (!isNaN(number) && number >= 0) {
      return `+${number.toFixed(2)}`;
    } else {
      return numberString;
    }
  };

  const convertFormatDateMonthDayYear = (getDateTime) => {
    // console.log('getDateTime : ', getDateTime);
    const inputDate = new Date(getDateTime);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const formattedDateTime = `${
      months[inputDate.getMonth()]
    } ${inputDate.getDate()}, ${inputDate.getFullYear()} ${inputDate.toLocaleTimeString(
      [],
      { hour: '2-digit', minute: '2-digit' }
    )}`;

    const formattedDate = `${
      months[inputDate.getMonth()]
    } ${inputDate.getDate()}, ${inputDate.getFullYear()}`;

    return formattedDate;
  };

  const fetchLotteryRewardResult = async (getDate) => {
    try {
      if (dataFetched) {
        return;
      }

      const requestBody = {
        lotteryId: '6505fad635d1e4e1ae1ae3e3',
        startTime: '00:00',
        endTime: '23:59',
      };

      const response = await axios.post(
        `${API_URL}/lottery-reward-result-en`,
        requestBody
      );

      // Check if the request was successful
      if (response.status === 200) {
        // console.log('response.data : ', response.data);

        let data = response.data.results.results;
        // if (data.r1 && data.r1.price !== null && data.r1.price !== undefined && data.r1.price !== "") {
        if (data.r1?.price) {
          setResultR1(response.data.results.results.r1);
          setFlagClose(false);
        } else {
          setResultR1({});
          setFlagClose(false);
        }

        if (data.r2?.price) {
          setResultR2(response.data.results.results.r2);
          setFlagClose(true);
        } else {
          setResultR2({});
          setFlagClose(false);
        }

        // console.log(response.data.results.stocks);

        setOpenPrice(response.data.openPrice);
        setPrevPrice(response.data.prevPrice);
        setStockData(response.data.results.stocks);
        setTimeOfResultShow(response.data.timeOfResultShow);
        let currentTimeHHMM = getCurrentTimeHHMM();
        setCurrentTimeHHMM(currentTimeHHMM);
        const selectedResult =
          currentTimeHHMM >= response.data.timeOfResultShow.Morning
            ? 'resultR1'
            : 'resultR2';

        setSelectedResult(selectedResult);

        setCurrentDate(
          convertFormatDateMonthDayYear(response.data.results.dateString)
        );

        setCurrentDate(
          convertFormatDateMonthDayYear(response.data.results.dateString)
        );

        // console.log(response.data.lotteryRewardResult);
        setDataResult(response.data.results);
        setLowestPrice(response.data.lowestPrice);
        setHighestPrice(response.data.highestPrice);
        setRewardResult(response.data.lotteryRewardResult);
        setHistoryData(response.data.historyReward);
        setSeriesData(response.data.seriesData);
      } else {
        console.error(
          'API request failed:',
          response.status,
          response.statusText
        );
        return null;
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data from the API:',
        error
      );
      return null;
    }
  };

  useEffect(() => {
    const now = new Date();
    const months = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    const year = now.getFullYear();
    const month = months[now.getMonth()];
    const day = String(now.getDate()).padStart(2, '0');

    const sendBodyDate = `${year}-${month}-${day}`;
    fetchLotteryRewardResult(sendBodyDate);

    const interval = setInterval(async () => {
      await fetchLotteryRewardResult(sendBodyDate);
    }, 3 * 60 * 1000); // Fetch data every 3 minutes

    return () => clearInterval(interval);
  }, [dataFetched]);

  const convertFormatDate = (dateString) => {
    const dateObj = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Get the month, day, and year components from the date object
    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  const convertFormatDateYYYYMMDD = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and format.
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  };

  const convertFormatDateDDMMYYYY = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and format.
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const convertFormatDateDDMMYYYYWithTime = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    const formattedHours = hours % 12 || 12;

    const formattedDate = `${day} ${month} ${year} ${formattedHours}:${minutes} ${ampm}`;

    return formattedDate;
  };

  const formatNumberWithCommas_v1 = (number) => {
    return number.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatNumberWithCommas = (number) => {
    // Check if the input is a valid number and not NaN
    if (typeof number === 'number' && !isNaN(number)) {
      return number.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      // If the input is not a valid number or NaN, return "0.00"
      return '0.00';
    }
  };

  const getRandomValueAroundPercentage = (value, percentage) => {
    // console.log('value, percentage : ', value, percentage);
    const minValue = value * percentage;
    const maxValue = -minValue;

    // Generate a random value within the defined range
    const randomValue = Math.random() * (maxValue - minValue) + minValue;

    // Check if the result is NaN or not a finite number
    if (isNaN(randomValue) || !isFinite(randomValue)) {
      //   console.warn(
      //     `Random value is not a valid number, returning the original value ${value}`
      //   );
      return value;
    }

    // Add the random value to the original value and round to two decimal places
    const result = (value + randomValue).toFixed(2);

    // console.log('result : ', result);
    return result;
  };

  const formatDateAsOf = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and format.
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Determine AM/PM based on UTC hours
    const ampm = date.getUTCHours() >= 12 ? 'pm' : 'am';

    const formattedDate = `AS OF ${hours}:${minutes} ${ampm} ${month}/${day}/${year} EDT`;

    return formattedDate;
  };

  return (
    <>
      <div className='header'>
        <img src='assets/img/gb-header.png' />
      </div>
      <div className='gb-content'>
        <div
          className={
            stockData[0]?.priceData[0]?.status === 'up'
              ? 'chart-section price-up'
              : 'chart-section price-down'
          }
        >
          <div className='price-groups'>
            <div className='price-info'>
              <div className='title'>
                <h1>UKX:IND</h1>
                <h2>FTSE 100 Index</h2>
              </div>
              <div className='price-box'>
                <div className='current'>
                  <h1>
                    {formatNumberWithCommas(
                      parseFloat(stockData[0]?.priceData[0]?.price)
                    )}
                  </h1>
                  <span>GBP</span>
                </div>
                <div className='changed'>
                  <div className='absolute'>
                    {parseFloat(stockData[0]?.priceData[0]?.diff)}
                  </div>
                  <div className='percent'>
                    {parseFloat(stockData[0]?.priceData[0]?.per)}%
                    {stockData[0]?.priceData[0]?.status === 'up' ? (
                      <i className='fas fa-caret-up' />
                    ) : (
                      <i className='fas fa-caret-down' />
                    )}
                  </div>
                </div>
                <div className='price-time'>
                  <span>{formatDateAsOf(dataResult.date)}</span>
                </div>
              </div>
              <ul>
                <li>
                  <div>
                    <label>OPEN</label>
                    <span>
                      {formatNumberWithCommas(parseFloat(openPrice?.price))}
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <label>PREV CLOSE</label>
                    <span>
                      {formatNumberWithCommas(parseFloat(prevPrice?.price))}
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <label>DAY RANGE</label>
                    <span>
                      {formatNumberWithCommas(parseFloat(lowestPrice))} -{' '}
                      {formatNumberWithCommas(parseFloat(highestPrice))}
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <label>52 WEEK RANGE</label>
                    <span>
                      {lowestPrice &&
                        formatNumberWithCommas(
                          parseFloat(
                            getRandomValueAroundPercentage(
                              parseFloat(lowestPrice),
                              0.23
                            )
                          )
                        )}
                      {''}-{''}
                      {formatNumberWithCommas(
                        parseFloat(
                          getRandomValueAroundPercentage(
                            parseFloat(highestPrice),
                            0.31
                          )
                        )
                      )}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='chart-box' style={{ position: 'relative' }}>
            <ul>
              <li className='active'>1D</li>
              <li>1M</li>
              <li>6M</li>
              <li>YTD</li>
              <li>1Y</li>
              <li>5Y</li>
            </ul>
            <div className='chart' style={{ overflow: 'hidden' }}>
              <div style={{ minHeight: 315 }}>
                <div style={{ width: '100%' }}>
                  <ApexChart
                    seriesData={seriesData}
                    width={width}
                    height={height}
                    colors={colors}
                  />
                </div>
              </div>
            </div>
            <div className='today-result'>
              <table className='table mb-0'>
                <tbody>
                  <tr>
                    <td>{currentDate}</td>
                    <td>บน</td>
                    <td
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: '#fe9e19',
                      }}
                    >
                      {timeOfResultShow && timeOfResultShow['Morning']
                        ? rewardResult?.Morning?.digits3
                        : rewardResult?.Evening?.digits3}
                    </td>
                    <td>ล่าง</td>
                    <td
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: '#fe9e19',
                      }}
                    >
                      {timeOfResultShow && timeOfResultShow['Morning']
                        ? rewardResult?.Morning?.digits2
                        : rewardResult?.Evening?.digits2}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='gb-footer'>
        <div className='footer'>
          UKX:IND - FTSE 100 Index
          <br />
          <a
            href='https://ukx-vi.com/'
            target='_blank'
            style={{ fontWeight: 'normal' }}
            className='txt-h'
          >
            www.ukx-vi.com
          </a>
        </div>
      </div>
    </>
  );
};

export default England;
