import React, { useEffect, useState } from 'react';
import axios from 'axios';

import ApexChart from './ApexChart';

import { myConfig } from '../../Constants/Constants';
const API_URL = myConfig.apiUrl;

// const API_URL = 'http://localhost:3300/api';
// const API_URL = 'https://bnagvip.slotxojackpot.com/api';

const Taiwan = ({ width, height, colors }) => {
  const [currentDate, setCurrentDate] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [resultR1, setResultR1] = useState({});
  const [resultR2, setResultR2] = useState({});
  const [flagClose, setFlagClose] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [timeOfResultShow, setTimeOfResultShow] = useState(null);
  const [isMorning, setIsMorning] = useState(null);
  const [selectedResult, setSelectedResult] = useState('');
  const [currentTimeHHMM, setCurrentTimeHHMM] = useState('');
  const [rewardResult, setRewardResult] = useState('');

  const getCurrentTimeHHMM = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const compareTimes = (time1, time2) => {
    if (!time1 || !time2) {
      // Handle undefined time strings gracefully
      return 0; // or return an appropriate value
    }

    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    if (hours1 < hours2) return -1;
    if (hours1 > hours2) return 1;
    if (minutes1 < minutes2) return -1;
    if (minutes1 > minutes2) return 1;
    return 0;
  };

  const formatNumberWithPlus = (numberString) => {
    const number = parseFloat(numberString);

    if (!isNaN(number) && number >= 0) {
      return `+${number.toFixed(2)}`;
    } else {
      return numberString;
    }
  };

  const convertFormatDateMonthDayYear = (getDateTime) => {
    // console.log('getDateTime : ', getDateTime);
    const inputDate = new Date(getDateTime);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const formattedDate = `${
      months[inputDate.getMonth()]
    } ${inputDate.getDate()}, ${inputDate.getFullYear()} ${inputDate.toLocaleTimeString(
      [],
      { hour: '2-digit', minute: '2-digit' }
    )}`;

    return formattedDate;
  };

  const fetchLotteryRewardResult = async (getDate) => {
    try {
      const requestBody = {
        lotteryId: '6505f8fa35d1e4e1ae1ae3b1',
        startTime: '09:00',
        endTime: '17:00',
      };

      const response = await axios.post(
        `${API_URL}/lottery-reward-result-tw`,
        requestBody
      );

      // Check if the request was successful
      if (response.status === 200) {
        // console.log('response.data -[Taiwan] : ', response.data);

        let data = response.data.results.results;
        // if (data.r1 && data.r1.price !== null && data.r1.price !== undefined && data.r1.price !== "") {
        if (data.r1?.price) {
          setResultR1(response.data.results.results.r1);
          setFlagClose(false);
        } else {
          setResultR1({});
          setFlagClose(false);
        }

        if (data.r2?.price) {
          setResultR2(response.data.results.results.r2);
          setFlagClose(true);
        } else {
          setResultR2({});
          setFlagClose(false);
        }

        // console.log(response.data.results.stocks);

        setStockData(response.data.results.stocks);
        setTimeOfResultShow(response.data.timeOfResultShow);
        let currentTimeHHMM = getCurrentTimeHHMM();
        setCurrentTimeHHMM(currentTimeHHMM);
        const selectedResult =
          currentTimeHHMM >= response.data.timeOfResultShow.Morning
            ? 'resultR1'
            : 'resultR2';

        setSelectedResult(selectedResult);

        setCurrentDate(convertFormatDate(response.data.results.dateString));

        // console.log(response.data.lotteryRewardResult);
        setRewardResult(response.data.lotteryRewardResult);
        setHistoryData(response.data.historyReward);
        setSeriesData(response.data.seriesData);
      } else {
        console.error(
          'API request failed:',
          response.status,
          response.statusText
        );
        return null;
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data from the API:',
        error
      );
      return null;
    }
  };

  useEffect(() => {
    const now = new Date();
    const months = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    const year = now.getFullYear();
    const month = months[now.getMonth()];
    const day = String(now.getDate()).padStart(2, '0');

    const sendBodyDate = `${year}-${month}-${day}`;
    fetchLotteryRewardResult(sendBodyDate);

    const interval = setInterval(async () => {
      await fetchLotteryRewardResult(sendBodyDate);
    }, 3 * 60 * 1000); // Fetch data every 3 minutes

    return () => clearInterval(interval);
  }, []);

  const convertFormatDate = (dateString) => {
    const dateObj = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Get the month, day, and year components from the date object
    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    // Create the formatted date string
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const convertFormatDateYYYYMMDD = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and format.
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  };

  const formatNumberWithCommas_v1 = (number) => {
    return number.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatNumberWithCommas = (number) => {
    // Check if the input is a valid number and not NaN
    if (typeof number === 'number' && !isNaN(number)) {
      return number.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      // If the input is not a valid number or NaN, return "0.00"
      return '0.00';
    }
  };

  return (
    <>
      <div className='header'>
        <img src='assets/img/tw-header.png' />
      </div>
      <div className='content'>
        <div className='index-head'>Index Charts</div>
        <div className='index-cont'>
          <div className='row m-0'>
            <div className='col-12 col-md-3 col-left p-0'>
              <ul>
                <li className='active'>
                  <div className='txt'>TAIEX</div>
                </li>
                <li className>
                  <div className='txt'>Main Index Info.</div>
                </li>
                <li className>
                  <div className='txt'>FTSE TWSE Taiwan 50 Index</div>
                  <br />
                  <div
                    className={
                      stockData[1]?.priceData[0].status === 'up' ? 'up' : 'down'
                    }
                  >
                    <span className='p'>
                      {formatNumberWithCommas(
                        parseFloat(stockData[1]?.priceData[0]?.price)
                      )}
                    </span>
                    &nbsp;
                    <span className='c'>
                      {stockData[1]?.priceData[0]?.diff} (
                      {stockData[1]?.priceData[0]?.per}%)
                    </span>
                    <br />
                    {convertFormatDateMonthDayYear(
                      stockData[1]?.priceData[0]?.time
                    )}
                  </div>
                </li>
                <li className>
                  <div className='txt'>TWSE CG 100 Index</div>
                  <br />
                  <div
                    className={
                      stockData[2]?.priceData[0].status === 'up' ? 'up' : 'down'
                    }
                  >
                    <span className='p'>
                      {formatNumberWithCommas(
                        parseFloat(stockData[2]?.priceData[0]?.price)
                      )}
                    </span>
                    &nbsp;
                    <span className='c'>
                      {stockData[2]?.priceData[0]?.diff} (
                      {stockData[2]?.priceData[0]?.per}%)
                    </span>
                    <br />
                    {convertFormatDateMonthDayYear(
                      stockData[2]?.priceData[0]?.time
                    )}
                  </div>
                </li>
              </ul>
            </div>
            <div className='col-12 col-md-9 col-right p-0 ps-md-3'>
              <div className='tabs'>
                <ul>
                  <li className='active'>Real Time</li>
                  <li>Week</li>
                  <li>Month</li>
                  <li>Quarter</li>
                  <li>Year</li>
                </ul>
              </div>
              <div className='chart' style={{ overflow: 'hidden' }}>
                <div style={{ minHeight: 195 }}>
                  <div style={{ width: '100%' }}>
                    <ApexChart
                      seriesData={seriesData}
                      width={width}
                      height={height}
                      colors={colors}
                    />
                  </div>
                </div>
              </div>
              <div className='chart-info text-center'>
                <div className='mx-2'>
                  {convertFormatDateYYYYMMDD(stockData[0]?.priceData[0]?.time)}
                </div>
                <div className='mx-2'>TAIEX:</div>
                <div className='mx-2'>
                  {formatNumberWithCommas(
                    parseFloat(stockData[0]?.priceData[0]?.price)
                  )}
                </div>
                <div
                  className={
                    stockData[0]?.priceData[0].per > 0 ? 'mx-2 up' : 'mx-2 down'
                  }
                >
                  {stockData[0]?.priceData[0]?.diff}
                </div>
                <div
                  className={
                    stockData[0]?.priceData[0].per > 0 ? 'mx-2 up' : 'mx-2 down'
                  }
                >
                  {stockData[0]?.priceData[0]?.per}%
                </div>
                <div className='mx-2'>Value</div>
                <div className='mx-2'>
                  {stockData[0]?.priceData[0]?.volumn} (100 million NTD)
                </div>
                <br />
                <div className='note mx-2'>
                  Odd-lot, Block, Off-hour, Auction and Tender Offer trading are
                  not included in Trade Volume.
                </div>
              </div>
              <div className='today-result'>
                <table className='table table-bordered'>
                  <tbody>
                    <tr>
                      <td>{currentDate}</td>
                      <td>บน</td>
                      <td
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          color: '#ad1326',
                        }}
                      >
                        {timeOfResultShow && timeOfResultShow['Morning']
                          ? rewardResult?.Morning?.digits3
                          : rewardResult?.Evening?.digits3}
                      </td>
                      <td>ล่าง</td>
                      <td
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          color: '#ad1326',
                        }}
                      >
                        {timeOfResultShow && timeOfResultShow['Morning']
                          ? rewardResult?.Morning?.digits2
                          : rewardResult?.Evening?.digits2}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center mt-3'>
        <a
          href='https://twse-vi.com.com/'
          target='_blank'
          style={{ fontSize: '110%' }}
          className='txt-h'
        >
          www.twse-vi.com
        </a>
      </div>
      <div className='tw-final-footer' />
    </>
  );
};

export default Taiwan;
