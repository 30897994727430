import React, { useEffect, useRef, useState } from 'react';

import ReactApexChart from 'react-apexcharts';
import './ApexChart.css';

function ApexChart({ seriesData, width, height, colors }) {
  // console.log('England --> width, height, colors : ', width, height, colors);
  const [dataSeries, setDataSeries] = useState([]);
  const [chartDataUk, setChartDataUK] = useState([]);
  const [hasMounted, setHasMounted] = useState(false);

  const chartRef = useRef(null);

  // const width = 777;
  // const height = 250;
  // const colors = ['#fe9e19'];

  useEffect(() => {
    // When seriesData changes, update dataSeries
    // Ensure that the timestamp values are converted to numbers
    const formattedData = seriesData.map((dataPoint) => ({
      x: Number(dataPoint.x), // Convert to number
      y: dataPoint.y,
    }));
    setDataSeries(formattedData);
  }, [seriesData]);

  useEffect(() => {
    setHasMounted(true); // Indicates the component has mounted
  }, []);

  useEffect(() => {
    // Whenever dataSeries changes, update chartData
    if (hasMounted) {
      setChartDataUK([
        {
          series: [
            {
              name: 'Price',
              data: dataSeries, // Use dataSeries here
            },
          ],
          options: {
            chart: {
              id: 'chartUK',
              group: 'socialUK',
              type: 'area',
              height: height,
              width: width,
            },
            colors: [colors],
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              type: 'datetime', // Use datetime type for x-axis
              labels: {
                style: {
                  colors: 'black',
                },
              },
              tooltip: {
                enabled: false, // Disable tooltip for x-axis
              },
            },
            yaxis: {
              opposite: true,
              labels: {
                style: {
                  colors: 'black',
                },
                formatter: function (val) {
                  // Round the value to two decimal places
                  const roundedValue = Math.round(val * 100) / 100;
                  // Convert the rounded value to a string and remove trailing ".00"
                  const formattedValue = roundedValue
                    .toFixed(2)
                    .replace(/\.00$/, '');
                  // Use commas for thousands separator
                  return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
              },
            },
            tooltip: {
              theme: 'dark',
              style: {
                fontSize: '14px',
              },
              x: {
                format: 'HH:mm', // Format tooltip x value as HH:mm
              },
            },
          },
        },
      ]);
    }
  }, [dataSeries, width, height, colors, hasMounted]);

  return (
    <>
      <div id='wrapper-uk' className='chart-container'>
        {/* Add a CSS class */}
        {chartDataUk.map((chart, index) => (
          <div key={`uk-${index}`} className='' id='chartUK'>
            <ReactApexChart
              id='chartUK'
              // options={chart.options}
              options={chart.options}
              // series={chart.series}
              series={[
                // Create a series with a name and the actual data points
                {
                  name: 'Price',
                  data: dataSeries,
                },
              ]}
              type={chart.options.chart.type}
              height={chart.options.chart.height}
              width={chart.options.chart.width}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default ApexChart;
